'use strict';

// Constructor
var Newsletter = function() {
  this.name = 'newsletter';
  console.log('%s module', this.name);

  var scrollMonitor = require('scrollmonitor');
  var element = document.querySelector('.newsletter');
  var viewport = scrollMonitor.create(element);

  // element.classList.add('is-in-view');

  viewport.enterViewport(function(thisEvent, thisElement) {
    thisElement.watchItem.classList.add('is-in-view');
  });

  viewport.exitViewport(function(thisEvent, thisElement) {
    thisElement.watchItem.classList.remove('is-in-view');
  });

};

module.exports = Newsletter;
