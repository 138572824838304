
(function(window, document) {

    'use strict';


    /** Modules **/

    var Footer = require('../_modules/footer/footer');
    var Hero = require('../_modules/hero/hero');
    var Link = require('../_modules/link/link');
    var Newsletter = require('../_modules/newsletter/newsletter');


    /** Bootstrap **/

    new Footer();
    new Hero();
    new Link();
    new Newsletter();


})(this, this.document);
