'use strict';

// Constructor
var Footer = function() {
  this.name = 'footer';
  console.log('%s module', this.name);

  var scrollMonitor = require('scrollmonitor');
  var element = document.querySelector('.footer');
  var viewport = scrollMonitor.create(element);

  // console.log('scrollMonitor', scrollMonitor, 'element', element, 'viewport', viewport);

  viewport.enterViewport(function(thisEvent, thisElement) {
    // console.log('enter viewport', thisElement, 'watchItem', thisElement.watchItem, 'classList', thisElement.watchItem.classList);
    thisElement.watchItem.classList.add('is-in-view');
  });

  viewport.exitViewport(function(thisEvent, thisElement) {
    // console.log('exit viewport', thisElement, 'watchItem', thisElement.watchItem, 'classList', thisElement.watchItem.classList);
    thisElement.watchItem.classList.remove('is-in-view');
  });

};

module.exports = Footer;
