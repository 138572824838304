'use strict';

// Constructor
var Link = function() {
  this.name = 'link';
  console.log('%s module', this.name);

  // Smoothscroll polyfill
  // http://caniuse.com/#feat=scrollintoview
  // https://www.npmjs.com/package/smoothscroll-polyfill
  require('smoothscroll-polyfill').polyfill();

  // Get anchors with hashes
  var smoothScroll = document.querySelectorAll('a[href*="#"]');
  Array.prototype.forEach.call(smoothScroll, function(el, i) {

    // Check for local valid hashes
    if (
        el.hash.length > 0
        &&
        el.hostname === location.hostname
    ) {

      // Handle clicks
      el.addEventListener('click', function(element) {
        element.preventDefault();

        // Get target element
        var target = document.querySelector(element.target.hash);
        if (target) {

            // Let's scroll smoothly
            target.scrollIntoView({
                behavior: 'smooth'
            });

            // @TODO: add proper focus
            // target.focus();
            // if (target !== document.activeElement) {
            //     console.log('not focussed', target, document.activeElement);
            //     target.tabIndex = -1;
            //     target.focus();
            // }
        }
      });

    }
  });
};

module.exports = Link;
